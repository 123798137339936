import 'bootstrap-sass/assets/javascripts/bootstrap/modal'

$('.jsEventForm')
  .submit(function (event) {
    event.preventDefault()

    const formData = new window.FormData()
    const { ajax_url } = window.haet_cr_ajax

    formData.append('author', $('#storyForm').find('.jsEventForm').find('input[name="author"]').val());
    formData.append('email', $('#storyForm').find('.jsEventForm').find('input[name="email"]').val());
    formData.append('title', $('#storyForm').find('.jsEventForm').find('input[name="title"]').val());
    formData.append('text', $('#storyForm').find('.jsEventForm').find('textarea[name="text"]').val());
    formData.append('tags', $('#storyForm').find('.jsEventForm').find('input[name="tags"]').val());

    formData.append('action', $('#storyForm').find('.jsEventForm').find('input[name="action"]').val());
    formData.append('security', $('#storyForm').find('.jsEventForm').find('input[name="security"]').val());

    formData.append('image', $('#storyForm').find('.jsEventForm').find('input[name="image_file"]')[0].files[0]);

    $.ajax({
      url: ajax_url,
      type: 'post',
      data: formData,
      dataType: 'json',
      processData: false,
      contentType: false,
      success: function (response) {
        if (response) {
          if (response.success) {
            $('#storyForm').find('.jsEventForm')[0].reset()
            $('#storyForm').modal('toggle')
          }
        }
      },
      error: function (response) {
        console.warn('error', response)
      }
    })

    return false
  })
