const languageSelector = $('.jsLanguageSelector')
const languageSelectorElements = languageSelector.find('.wpml-ls-item a span')

languageSelectorElements.each(function (index, element) {
  const $element = $(element)

  $element.text($element.text().slice(0, 2))
})

languageSelector.show()
