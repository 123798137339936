const hamburger = $('.jsHamburger')
const headerNav = $('.m-header-nav')
const closeHeaderNav = headerNav.find('.menu-close')

hamburger.on('click', function () {
  headerNav.fadeIn('is-open')
})

closeHeaderNav.on('click', function () {
  headerNav.fadeOut('is-open')
})
