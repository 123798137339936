const filterLink = $('.m-filters-item--clickable a')
const activeClass = 'is-active'

let isAnimating = false

filterLink.on('click', function (e) {
  e.preventDefault()

  const _this = $(this)

  if (_this.hasClass('active') || isAnimating) {
    return
  }

  const data = _this.data()
  const transition = 'fade'
  const speed = 300

  isAnimating = true

  _this
    .addClass(activeClass)
    .parent()
    .siblings('li')
    .children('a')
    .removeClass(activeClass)

  $.fn.almFilter(transition, speed, data)
})

$.fn.almFilterComplete = function () {
  isAnimating = false
}
