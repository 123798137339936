const COOKIE_NAME = 'cookie'
const COOKIE_VALUE = 'accepted'
const COOKIE_EXPIRE = 365

const mCookie = $('.jsCookieInfo')
const mCookieBtn = mCookie.find('button')

const getDate = function (exdays = 365) {
  const date = new Date()

  date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000))

  return date
}

const getCookieContent = function (name, value, date) {
  return `${name}=${value}; expires=${date.toUTCString()}; domain=${window.location.hostname}; path=/; secure`
}

const setCookie = function (name = COOKIE_NAME, value = COOKIE_VALUE, exdays = COOKIE_EXPIRE) {
  document.cookie = getCookieContent(name, value, getDate(exdays))
}

const getCookie = function (name = COOKIE_NAME, value = COOKIE_VALUE) {
  return document.cookie.split('; ').indexOf(`${name}=${value}`) !== -1
}

// Set cookies onAccept
mCookieBtn.on('click', function () {
  mCookie.hide(setCookie())
})

// Show/hide coockie
getCookie() ? mCookie.hide() : mCookie.show()
